'use client'

import { Button } from '@/components/ui/button';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen">
      <h2 className="mb-4">Something went wrong!</h2>
      <Button onClick={() => reset()}>Try again</Button>
      {error.message}
    </div>
  )
}
